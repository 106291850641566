import SecureLS from 'secure-ls'

document.addEventListener 'ajax:success', (eve) ->
  uid = eve.detail[0].user
  final_data = eve.detail[0].product

  unless uid or final_data
    Swal.fire
      icon: 'error'
      text: 'Error inesperado'
    return false

  ls = new SecureLS()
  actual_cart = ls.get('cart')
  actual_cart = {} unless actual_cart
  actual_cart[uid] = [] unless actual_cart[uid]

  items_checker = actual_cart[uid].some((item) => (item.presentation_id == final_data.presentation_id))
  if items_checker
    for item, value of actual_cart[uid]
      if value.presentation_id == final_data.presentation_id
        if value.quantity < parseInt final_data.limit
          value.quantity += parseInt final_data.quantity
          Swal.fire({
            title: eve.detail[0].message
            icon: 'success',
            showConfirmButton: false,
            footer: "
							<div class='uk-flex uk-flex-wrap uk-flex-around'>
								<a class='uk-button black-button uk-margin-right' href='/customers/shopping_carts'>Ir al carrito</a>
								<a class='uk-button red-button' href='/productos'>Ver más productos</a>
							</div>
						"
          })
        else
          Swal.fire({
            icon: 'error',
            title: "Error",
            text: "Número de unidades no válido (Unidades disponibles: #{final_data.limit})"
          })
  else
    actual_cart[uid].push(final_data)
    the_badge = document.getElementById('cart-counter-badge')
    old_value = if the_badge.innerHTML then parseInt(the_badge.innerHTML) else 0
    the_badge.innerHTML = old_value + 1
    Swal.fire
      icon: 'success'
      text: eve.detail[0].message
      showConfirmButton: false
      footer: "
				<div class='uk-flex uk-flex-wrap uk-flex-around'>
					<a class='uk-button black-button uk-margin-right' href='/customers/shopping_carts'>Ir al carrito</a>
					<a class='uk-button red-button' href='/productos'>Ver más productos</a>
				</div>
			"
  ls.set('cart', actual_cart)
document.addEventListener 'ajax:error', (eve) ->
  switch eve.detail[2].status
    when 500
      Swal.fire
        icon: 'error'
        title: 'Error inesperado'
        text: 'Parece que hay un problema con el servidor, Intenta de nuevo en unos minutos'
    when 400
      errors = []
      errors = eve.detail[0].errors if eve.detail[0].errors
      Swal.fire
        icon: 'error'
        title: eve.detail[0].title
        html: "
					<p>#{eve.detail[0].message}</p>
					<ul>
            <li>#{errors.join('</li><li>') }</li>
					</ul>
				"
